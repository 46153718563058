import { getFirUserAndStatus } from '../../Utils'
import { NO_USER } from '../firebase/AuthData'
import { UpdateUserParams } from '../types/users.types'
import { QueryParams, makeAPIRequest } from '../utils/request'
import { userEndpoints } from '../utils/restEndpoints'

export const getUser = async (data: any) => {
  console.log('Getting user in getUser')
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (user) {
    const token = await user.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  const response = await makeAPIRequest(
    userEndpoints.GET_OR_CREATE_USER,
    {
      method: 'GET',
      apiVersion: '4',
      headerParams: headerParams,
    }
  )
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return data //Passed in firebase data
  }
  return response?.data?.data
}

export const createUser = async (data: any) => {
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (!user) {
    return Promise.resolve(NO_USER)
  }
  const token = await user.getIdToken()
  headerParams.Authorization = `Bearer ${token}`
  let body = JSON.stringify(data)
  const response = await makeAPIRequest(
    userEndpoints.GET_OR_CREATE_USER,
    {
      method: 'POST',
      apiVersion: '4',
      headerParams: headerParams,
      body: body,
    }
  )
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return data //Passed in firebase data
  }
  return response?.data?.data
}

export const updateUserProfile = async (data: UpdateUserParams) => {
  const { user, isAnonymous } = await getFirUserAndStatus()
  if (!user || isAnonymous) return false
  let headerParams: QueryParams = {}
  const token = await user.getIdToken()
  headerParams.Authorization = `Bearer ${token}`
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(userEndpoints.UPDATE_USER, {
      method: 'PUT',
      apiVersion: '3',
      headerParams: headerParams,
      body: body,
    })
    if (response?.error) {
      console.error(response.error?.message || 'Unknown error')
      return false
    }
    return true
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

/**
 * Fetch a user's public gamification profile
 */
export const getPublicGamificationProfile = async (userId: string) => {
  let headerParams: QueryParams = {}
  headerParams.Authorization =
    `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || ''
  const route = `${userEndpoints.PUBLIC_PROFILES}/${userId}/plln-profile`
  try {
    const response = await makeAPIRequest(route, {
      method: 'GET',
      apiVersion: '2',
      headerParams: headerParams,
    })
    if (response?.error) {
      console.error(response.error?.message)
      return false
    }
    return response.data
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

export const updateFavoriteTeam = async (team: string) => {
  if (!team) return Promise.resolve({ success: false, team: null })
  const data: object = {
    favTeam: team,
  }
  try {
    const success = await updateUserProfile(data)
    return { success, team: team }
  } catch (err: unknown) {
    console.error(err)
    return { success: false, team: null }
  }
}

/**
 * Attempt to send referrer credit to backend
 */
export const addReferral = async ({
  userId,
  referrerId,
  source,
  year,
}: {
  userId: string
  referrerId: string
  source: string
  year: number
}): Promise<any> => {
  let headerParams = { userId: userId }
  const body: { referrerId: string; source: string; year: number } = {
    referrerId: referrerId,
    source: source,
    year: year,
  }
  try {
    const res = await makeAPIRequest(userEndpoints.ADD_REFERRAL, {
      method: 'PUT',
      apiVersion: '2',
      headerParams: headerParams as QueryParams,
      body: JSON.stringify(body),
    })
    return res
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}
